import React, { useMemo, useState } from 'react'
import { graphql, Link } from 'gatsby'
import kebabCase from 'lodash.kebabcase'
import idx from 'idx'

import isAfter from 'date-fns/isAfter'
import { DrupalAuthenticationProvider } from 'react-drupal'
import { diffInDays } from '../../utils/diffInDays'
import Country from '../components/Country'
import Layout from '../components/Layout'
import JoinButton from '../components/JoinButton'
import Seo from '../components/Seo'

import Metatags from '../components/Metatags'
import { decode } from '../../utils/textToHtml'
import './styles/voyage.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const profilePhoto = src =>
  `https://res.cloudinary.com/dvjhq5dpg/image/fetch/h_200,w_200,c_fill,r_max/v1546964241/https://dev-skillsailors.pantheonsite.io/${src}`

const thumbnailImages = src =>
  `https://res.cloudinary.com/dvjhq5dpg/image/fetch/h_375,w_575/v1546964241/https://dev-skillsailors.pantheonsite.io/${src}`

const Voyage = ({ data, location }) => {
  const [totalSeats] = useState(() => {
    if (!data.nodeVoyage.relationships.field_voyage_accepted_users)
      return parseInt(data.nodeVoyage.field_seats_total, 10)
    return (
      parseInt(data.nodeVoyage.field_seats_total, 10) -
      parseInt(
        data.nodeVoyage.relationships.field_voyage_accepted_users.length,
        10
      )
    )
  })
  const sortTrips = useMemo(
    () =>
      data.allNodeTrip.edges
        .map(({ node }) => {
          const img = idx(node, _ => _.relationships.field_image[0].uri.url)
          const secondaryImg = idx(
            node,
            _ => _.relationships.field_image[1].uri.url
          )
          return {
            ...node,
            img,
            secondaryImg: secondaryImg ? secondaryImg : img,
            country: `${node.field_address_1.locality}, ${(
              <Country countryCode={node.field_address_1.country_code} />
            )}`,
          }
        })
        .sort((a, b) => new Date(a.field_date) - new Date(b.field_date)),
    [data]
  )

  const tripFilter = item =>
    Date.now() <= new Date(item.field_end_date).getTime()
  const tripPastFilter = item =>
    Date.now() >= new Date(item.field_end_date).getTime()

  const [firstTrip] = sortTrips

  const format_field_start_date = idx(firstTrip, _ => _.format_field_start_date)
  const field_format_date = idx(firstTrip, _ => _.field_format_date)

  const isStarting = isAfter(new Date(), new Date(field_format_date))

  return (
    <Layout>
      <Seo title={data.nodeVoyage.title} />
      <Metatags
        title={data.nodeVoyage.title}
        description={decode(data.nodeVoyage.body.value)}
      />

      <div className="layout-narrow">
        <div className="layout-narrow__inner">
          <h1>{data.nodeVoyage.title}</h1>
          <Link
            className="voyage__captain-info"
            to={
              '/user/' + data.nodeVoyage.relationships.uid.drupal_internal__uid
            }
          >
            <img
              alt="Captain"
              className="voyage__captain-icon"
              src={profilePhoto(
                idx(
                  data,
                  _ =>
                    _.nodeVoyage.relationships.uid.relationships
                      .field_user_profile.relationships.field_user_profile_image
                      .uri.url
                )
              )}
            />
            <div>
              <div className="voyage__captain-name">
                {data.nodeVoyage.relationships.uid.name}
              </div>
              <div className="voyage__captain-title">Trip Captain</div>
            </div>
          </Link>
        </div>
      </div>

      <div className="layout-narrow">
        <div className="layout-narrow__inner voyage__margin-top">
          <div
            className="voyage__body"
            dangerouslySetInnerHTML={{
              __html: data.nodeVoyage.body.value,
            }}
          />
          <div className="voyage__meta">
            <div className="voyage__section voyage__section-cost voyage__flex">
              <div>
                <div className="voyage__cost">
                  ${data.nodeVoyage.field_monthly_cost} per month
                </div>
                <div className="voyage__days voyage__section">
                  {isStarting ? 'Started ' : 'Starting '}
                  {format_field_start_date}
                </div>
              </div>
              <ul className="voyage__ameneties">
                {data.nodeVoyage.field_amenities.map(amenity => (
                  <li key={amenity} className="voyage__amenity">
                    <span>&#183;</span> {amenity}
                  </li>
                ))}
              </ul>
            </div>

            <div className="voyage__section">
              {data.nodeVoyage.field_seats_total && (
                <React.Fragment>
                  {data.nodeVoyage.relationships.field_voyage_accepted_users ? (
                    <p className="py-2 m-0 pb-6">
                      {totalSeats > 1
                        ? `${totalSeats} seats left`
                        : `${totalSeats} seat left`}
                    </p>
                  ) : (
                    <p className="py-2 m-0">
                      {parseInt(data.nodeVoyage.field_seats_total, 10)} seats
                      left
                    </p>
                  )}
                </React.Fragment>
              )}
              <DrupalAuthenticationProvider>
                {({ user }) => {
                  const user_id = idx(user, _ => _.data.id)
                  const drupal_id = idx(data, _ => _.nodeVoyage.drupal_id)
                  return (
                    <JoinButton
                      voyageTitle={data.nodeVoyage.title}
                      user={user_id}
                      drupal_id={data.nodeVoyage.drupal_id}
                      to={`/admin/join-voyage/${drupal_id}`}
                    />
                  )
                }}
              </DrupalAuthenticationProvider>
            </div>
          </div>

          <div className="voyage__flex-secc2">
            {data.nodeVoyage.relationships.field_voyage_accepted_users &&
              data.nodeVoyage.relationships.field_voyage_accepted_users
                .length && (
                <div className="voyage__attendees-wrapper">
                  <h2 className="voyage__label">Who's Coming</h2>
                  <ul className="voyage__attendees">
                    {data.nodeVoyage.relationships.field_voyage_accepted_users.map(
                      attende => {
                        const profilePhoto = idx(
                          attende,
                          _ =>
                            _.relationships.field_user_profile.relationships
                              .field_user_profile_image.uri.url
                        )
                        const profilePhotoURL = profilePhoto
                          ? `https://res.cloudinary.com/dvjhq5dpg/image/fetch/h_80,w_80,c_fill,r_max/v1546964241/https://dev-skillsailors.pantheonsite.io/${profilePhoto}`
                          : '/sailboat.svg'
                        return (
                          <li
                            key={attende.drupal_internal__uid}
                            className="voyage__attendee-item"
                          >
                            <Link
                              to={`/user/${attende.drupal_internal__uid}`}
                              className=""
                            >
                              <div className="voyage__attendee-data">
                                <img
                                  alt={attende.name}
                                  className="voyage__attendee-icon"
                                  src={profilePhotoURL}
                                />
                              </div>
                            </Link>
                          </li>
                        )
                      }
                    )}
                    <li className="voyage__attendee-item voyage__attendee-number">
                      +{' '}
                      {
                        data.nodeVoyage.relationships
                          .field_voyage_accepted_users.length
                      }{' '}
                      more
                    </li>
                  </ul>
                </div>
              )}
          </div>

          <div className="voyage__trips">
            <div className="voyage__trip-section">
              <h2>UPCOMING TRIPS</h2>
              <div>
                {sortTrips.filter(tripFilter).map(item => {
                  return (
                    <Link
                      key={item.drupal_internal__nid}
                      to={`/trip/${item.drupal_internal__nid}/${kebabCase(
                        item.title
                      )}`}
                      className="voyage__items-wrapper"
                    >
                      <div className="voyage__item">
                        <img
                          alt={item.title}
                          src={thumbnailImages(item.secondaryImg)}
                          className="voyage__item-img"
                        />
                        <div className="voyage__item-inner">
                          <div className="voyage__item-top-flex">
                            <div className="voyage__item-date">
                              {item.format_field_date}
                            </div>
                            <div className="voyage__item-days-left">
                              {diffInDays(item.field_date, item.field_end_date)}{' '}
                              days{' '}
                            </div>
                          </div>
                          <h2 className="voyage__item-title">
                            {item.field_address_1.locality},{' '}
                            <Country
                              countryCode={item.field_address_1.country_code}
                            />
                          </h2>
                          <div className="voyage__item-body">
                            {item.field_tag_line}
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>

            {sortTrips.filter(tripPastFilter).length > 0 && (
              <div className="voyage__trip-section">
                <h2>PAST TRIPS</h2>
                <div>
                  {sortTrips.filter(tripPastFilter).map(item => {
                    return (
                      <Link
                        key={item.drupal_internal__nid}
                        to={`/trip/${item.drupal_internal__nid}/${kebabCase(
                          item.title
                        )}`}
                        className="voyage__items-wrapper"
                      >
                        <div className="voyage__item">
                          <img
                            alt={item.title}
                            src={thumbnailImages(item.secondaryImg)}
                            className="voyage__item-img"
                          />
                          <div className="voyage__item-inner">
                            <div className="voyage__item-top-flex">
                              <div className="voyage__item-date">
                                {item.format_field_date}
                              </div>
                              <div className="voyage__item-days-left">
                                {diffInDays(
                                  item.field_date,
                                  item.field_end_date
                                )}{' '}
                                days{' '}
                              </div>
                            </div>
                            <h2 className="voyage__item-title">
                              {item.field_address_1.locality},{' '}
                              <Country
                                countryCode={item.field_address_1.country_code}
                              />
                            </h2>
                            <div className="voyage__item-body">
                              {item.field_tag_line}
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($nid: Int) {
    nodeVoyage(drupal_internal__nid: { eq: $nid }) {
      drupal_id
      field_amenities
      field_total_days
      field_monthly_cost
      field_seats_total
      field_start_date(formatString: "MMMM Do, YYYY")
      title
      body {
        value
      }
      relationships {
        field_voyage_accepted_users {
          drupal_internal__uid
          name
          relationships {
            field_user_profile {
              relationships {
                field_user_profile_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
        uid {
          drupal_internal__uid
          name
          relationships {
            field_user_profile {
              relationships {
                field_user_profile_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeTrip(
      filter: {
        relationships: {
          node__voyage: { elemMatch: { drupal_internal__nid: { eq: $nid } } }
        }
      }
    ) {
      edges {
        node {
          title
          field_tag_line
          field_address_1 {
            locality
            country_code
          }
          field_date
          field_format_date: field_date(formatString: "MM-DD-YYYY")
          format_field_start_date: field_date(formatString: "MMMM Do, YYYY")
          format_field_date: field_date(formatString: "MMMM D")
          field_end_date
          drupal_internal__nid
          relationships {
            field_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Voyage
